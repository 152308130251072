import React, { Component } from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import PropTypes from 'prop-types'
import styles from './header.module.sass'

import Notification from './notification'
// import Banner from '../components/banner'

import logo from '../assets/images/logo.svg'
import menuIcon from '../assets/images/menu.svg'
import closeIcon from '../assets/images/close.svg'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

var utcnow = Date.now()
var live = utcnow >= 1658355600000 ? true : false
var ended = utcnow > 1658355300000

const bannerTitle =
   'Stagflation: What Happens When Inflation and Recession Collide?'
const bannerLink = 'https://preview.spcfinancial.com/events'

class Header extends Component {
   constructor(props) {
      super(props)

      this.openMenu = this.openMenu.bind(this)
      this.closeMenu = this.closeMenu.bind(this)

      this.state = {
         closed: true,
         closing: false,
         opening: false,
         opened: false,
         banner: null,
      }
   }

   openMenu() {
      this.setState({
         closed: false,
         closing: false,
         opening: true,
         opened: false,
      })
      setTimeout(() => {
         this.setState({
            closed: false,
            closing: false,
            opening: false,
            opened: true,
         })
      }, 1.6 * 1000)
      if (this.state.opening && this.state.closing) {
         this.setState({
            closed: false,
            closing: false,
            opening: true,
            opened: false,
         })
      }
      if (this.state.opened && this.state.opening) {
         this.setState({
            closed: false,
            closing: false,
            opening: true,
            opened: false,
         })
      }
   }

   closeMenu() {
      this.setState({
         closed: false,
         closing: true,
         opening: false,
         opened: false,
      })
      setTimeout(() => {
         this.setState({
            closed: true,
            closing: false,
            opening: false,
            opened: false,
         })
      }, 0.8 * 1000)
      if (this.state.closing && this.state.opening) {
         this.setState({
            closed: false,
            closing: true,
            opening: false,
            opened: false,
         })
      }
      if (this.state.closed && this.state.closing) {
         this.setState({
            closed: false,
            closing: true,
            opening: false,
            opened: false,
         })
      }
      if (this.state.opened && this.state.closed) {
         this.setState({
            closed: true,
            closing: false,
            opening: false,
            opened: false,
         })
      }
   }

   render() {
      const menuClosed = this.state.closed ? styles.closed : ''
      const menuClosing = this.state.closing ? styles.closing : ''
      const menuOpening = this.state.opening ? styles.opening : ''
      const menuOpened = this.state.opened ? styles.opened : ''

      return (
         <>
            <div className={styles.headers}>
               <Notification />

               <div className={styles.header__quick}>
                  <div className={styles.info}>
                     {!ended && (
                        <span className={styles.address}>
                           <a
                              href={bannerLink}
                              rel="noopener noreferrer"
                              target="_blank"
                              className="button button--white button--rounded button--small button--has-icon">
                              {live ? 'Join' : 'Register'} <span>&rarr;</span>
                           </a>
                           &nbsp;&nbsp;&nbsp;
                           <b>
                              <a href="https://preview.spcfinancial.com/events">
                                 {bannerTitle}
                              </a>
                           </b>
                        </span>
                     )}
                     {ended && (
                        <>
                           <span className={styles.phone}>
                              <OutboundLink href="tel:+13017706800">
                                 (301) 770-6800
                              </OutboundLink>
                           </span>
                           <span className={styles.separator}>&mdash;</span>
                           <span className={styles.address}>
                              <OutboundLink
                                 href="//google.com/maps/dir//SPC+Financial,+Inc.,+3202+Tower+Oaks+Blvd+%23400,+Rockville,+MD+20852/@39.0593281,-77.220709"
                                 target="_blank"
                                 rel="noopener noreferrer">
                                 3202 Tower Oaks Blvd., Ste. 400, Rockville, MD
                                 20852
                              </OutboundLink>
                           </span>
                        </>
                     )}

                     {/* <span className={styles.separator}>&mdash;</span> */}

                     {/* <span className={styles.phone}>
                        <a href="tel:+13017706800">
                           We're Available: <b>(301) 770-6800</b>
                        </a>
                     </span> */}
                     {/* {!!ended && (
                        <>
                           <span className={styles.separator}>&mdash;</span>
                           <span
                              className={styles.phone}
                              dangerouslySetInnerHTML={{
                                 __html: `
                                    <a
                                       href="#"
                                       onClick="LC_API.open_chat_window();return false;"
                                       class="button button--white button--link button--has-icon">
                                       Questions? Let's Chat <span>&rarr;</span>
                                    </a>
                                 `,
                              }}></span>
                        </>
                     )} */}

                     {/* <span className={styles.phone}>
                        <OutboundLink href="tel:+13017706800">
                           (301) 770-6800
                        </OutboundLink>
                     </span>
                     <span className={styles.separator}>&mdash;</span>
                     <span className={styles.address}>
                        <OutboundLink
                           href="//google.com/maps/dir//SPC+Financial,+Inc.,+3202+Tower+Oaks+Blvd+%23400,+Rockville,+MD+20852/@39.0593281,-77.220709"
                           target="_blank"
                           rel="noopener noreferrer">
                           3202 Tower Oaks Blvd., Ste. 400, Rockville, MD 20852
                        </OutboundLink>
                     </span> */}
                  </div>
                  <div className={styles.links}>
                     <Link
                        to="https://assets.ctfassets.net/wt3nvc89w481/2LfJImfzhZ1w3QVn6BF1pn/cd9cf0cc1fe34ea63c66191a88f23864/SPC_PART_2A_ADV_FIRM_BROCHURE_-_October_30_2024__00299804xBE0D5_.PDF"
                        className={[
                           styles.button,
                           'button',
                           'button--white',
                           'button--link',
                        ].join(' ')}>
                        Form ADV Part 2A: Firm Brochure
                     </Link>
                     <Link
                        to="https://assets.ctfassets.net/wt3nvc89w481/2N9m6pUJlLhXoH8sAJt1JD/57b8d67ce6f495610a3c2891513dab5f/SPC_PART_3_ADV_FORM_CRS_-_October_30_2024__00299805xBE0D5_.PDF"
                        className={[
                           styles.button,
                           'button',
                           'button--white',
                           'button--link',
                        ].join(' ')}>
                        Form ADV Part 3: CRS
                     </Link>
                     <Link
                        to="/clients"
                        className={[
                           styles.button,
                           'button',
                           'button--white',
                           'button--link',
                        ].join(' ')}>
                        Client Dashboard
                     </Link>
                     <Link
                        to="/contact"
                        className={[
                           styles.button,
                           'button',
                           'button--white',
                           'button--link',
                        ].join(' ')}>
                        Contact Us
                     </Link>
                     <Link
                        to="/new-client"
                        className={[
                           styles.button,
                           'button',
                           'button--small',
                           'button--secondary',
                           'button--rounded',
                        ].join(' ')}>
                        Become a Client
                     </Link>
                  </div>
               </div>

               <header className={styles.header__main}>
                  <div className={styles.logo__block}>
                     <Link to="/">
                        <img
                           src={logo}
                           alt={this.props.siteTitle}
                           className={styles.logo}
                        />
                     </Link>
                  </div>

                  <div className={styles.header__tagline}>
                     <p>{this.props.pageTitle}</p>
                  </div>

                  <div className={styles.menu__control} onClick={this.openMenu}>
                     <img
                        src={menuIcon}
                        className={[styles.menu__button, styles.image].join(
                           ' '
                        )}
                        id="open-menu"
                        alt="OPEN MENU"
                        aria-label="Open Menu"
                     />
                  </div>
               </header>
               {/* {this.state.banner && (
                  <div className={styles.section__banner}>
                     <Banner
                        small="true"
                        title={!!live ? 'LIVE: ' : ''}
                        text="[VIRTUAL TOWN HALL]  &nbsp; Market Recap + CARES Act: Small Business Provisions Discussion"
                        // text={`[${!!live ? 'LIVE: ' : ''}VIRTUAL TOWN HALL]   Market Recap + CARES Act: Small Business Provisions Discussion`}
                        actionText={!!live ? 'Join' : 'Register'}
                        target="_blank"
                        actionTo="//raymondjames.zoom.us/webinar/register/WN_XWq-996nQge6w6mu_LDhNw"
                        questionLink="true"
                     />

                     <span>
                        <button
                           className="button button--secondary button--large button--link button--rounded"
                           onClick={() => this.closeBanner()}>
                           <span>CLOSE&nbsp;&nbsp;</span>
                           <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                     </span>
                  </div>
               )} */}
            </div>

            <nav
               className={[
                  menuClosed,
                  menuClosing,
                  menuOpening,
                  menuOpened,
               ].join(' ')}>
               <div className={styles.inner}>
                  <div
                     className={styles.menu__control}
                     onClick={this.closeMenu}>
                     <img
                        src={closeIcon}
                        className={[styles.close__button, styles.image].join(
                           ' '
                        )}
                        aria-hidden="true"
                        id="close-menu"
                        alt="CLOSE MENU"
                        aria-label="Close Menu"
                     />
                  </div>

                  <ul className={styles.navigation}>
                     <li className={styles.direct_links}>
                        <Link
                           to="/"
                           className={styles.link}
                           activeClassName={styles.active}
                           onClick={this.closeMenu}>
                           Home
                        </Link>
                        <Link
                           to="/faq/"
                           className={styles.link}
                           activeClassName={styles.active}
                           onClick={this.closeMenu}>
                           FAQs
                        </Link>
                        <Link
                           to="/ctn/"
                           className={styles.link}
                           activeClassName={styles.active}
                           onClick={this.closeMenu}>
                           Current Trends &amp; News
                        </Link>
                     </li>

                     <li>
                        <Link
                           to="/company"
                           className={styles.link}
                           activeClassName={styles.active}
                           onClick={this.closeMenu}>
                           Company
                        </Link>
                        <ul>
                           <li>
                              <Link
                                 to="/about/"
                                 className={styles.link}
                                 activeClassName={styles.active}
                                 onClick={this.closeMenu}>
                                 About Us
                              </Link>
                           </li>
                           <li>
                              <Link
                                 to="/services/"
                                 className={styles.link}
                                 activeClassName={styles.active}
                                 onClick={this.closeMenu}>
                                 Our Services
                              </Link>
                           </li>
                           <li>
                              <Link
                                 to="/team/"
                                 className={styles.link}
                                 activeClassName={styles.active}
                                 onClick={this.closeMenu}>
                                 Our Financial Team
                              </Link>
                           </li>
                           <li>
                              <Link
                                 to="/rankings/"
                                 className={styles.link}
                                 activeClassName={styles.active}
                                 onClick={this.closeMenu}>
                                 Rankings &amp; Recognition
                              </Link>
                           </li>
                           <li>
                              <Link
                                 to="/careers"
                                 className={styles.link}
                                 activeClassName={styles.active}
                                 onClick={this.closeMenu}>
                                 Careers
                              </Link>
                           </li>
                        </ul>
                     </li>

                     <li>
                        <Link
                           to="/clients/"
                           className={styles.link}
                           activeClassName={styles.active}
                           onClick={this.closeMenu}>
                           Clients
                        </Link>
                        <ul>
                           {/* <li>
                              <Link
                                 to="/clients/pay/"
                                 className={styles.link}
                                 activeClassName={styles.active}
                                 onClick={this.closeMenu}>
                                 Pay Bill
                              </Link>
                           </li> */}
                           {/* <li>
                              <Link
                                 to="/clients/client-access/"
                                 className={styles.link}
                                 activeClassName={styles.active}
                                 onClick={this.closeMenu}>
                                 Client Access
                              </Link>
                           </li> */}
                           <li>
                              <Link
                                 to="/clients/insights/"
                                 className={styles.link}
                                 activeClassName={styles.active}
                                 onClick={this.closeMenu}>
                                 Insights
                              </Link>
                           </li>
                           <li>
                              <Link
                                 to="/clients/forms/"
                                 className={styles.link}
                                 activeClassName={styles.active}
                                 onClick={this.closeMenu}>
                                 Forms
                              </Link>
                           </li>
                           <li>
                              <Link
                                 to="/schedule/"
                                 className={styles.link}
                                 activeClassName={styles.active}
                                 onClick={this.closeMenu}>
                                 Schedule an Appointment
                              </Link>
                           </li>
                        </ul>
                     </li>

                     <li className={styles.ctas}>
                        <Link
                           to="/new-client/"
                           className={[
                              styles.button,
                              'button',
                              'button--secondary',
                              'button--large',
                              'button--full',
                           ].join(' ')}
                           onClick={this.closeMenu}>
                           Become a Client
                        </Link>
                        <Link
                           to="/contact/"
                           className={[
                              styles.button,
                              'button',
                              'button--white',
                              'button--large',
                              'button--bordered',
                              'button--full',
                           ].join(' ')}
                           onClick={this.closeMenu}>
                           Contact Us
                        </Link>
                     </li>
                  </ul>

                  <div className={styles.menu__footer}>
                     <p>
                        <OutboundLink href="tel:+13017706800">
                           (301) 770-6800
                        </OutboundLink>
                     </p>
                     <p>
                        <OutboundLink
                           href="//google.com/maps/dir//SPC+Financial,+Inc.,+3202+Tower+Oaks+Blvd+%23400,+Rockville,+MD+20852/@39.0593281,-77.220709"
                           target="_blank"
                           rel="noopener noreferrer">
                           3202 Tower Oaks Blvd., Ste. 400, Rockville, MD 20852
                        </OutboundLink>
                     </p>
                  </div>
               </div>
            </nav>
         </>
      )
   }
}

Header.propTypes = {
   siteTitle: PropTypes.string,
   pageTitle: PropTypes.string,
}

Header.defaultProps = {
   siteTitle: ``,
   pageTitle: `Human-Centric Wealth Management™`,
}

export default Header
